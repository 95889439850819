import React from 'react'
import {
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useEventContext } from '../../../contexts/EventProvider'
import { useEventHandlersV2 } from '../../../hooks/useEventHandlers'

const ModalPreview = ({ onClose, isOpen, renderTime, previewJobsCount, isFirstPreview, dispatchPreview, noMedia }) => {
	const { event } = useEventContext()
	const navigate = useNavigate()
	const handlers = useEventHandlersV2(event.uuid)

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />

			{isFirstPreview && noMedia ? (
				<ModalContent>
					<ModalCloseButton />
					<ModalBody>
						<VStack textAlign="center" spacing="1rem">
							<Image src={'/assets/images/graphics/upload-modal-icon.svg'} alt="Preview Started" />
							<Heading size="lg" variant="hero">
								Add media to make a preview!
							</Heading>
							<Text>Curious to see what a final video could look like? View our theme examples.</Text>
						</VStack>
					</ModalBody>
					<ModalFooter>
						<HStack w="full" spacing="1rem" justifyContent="center">
							<Button
								onClick={() => {
									navigate(handlers.root)
								}}>
								Add Media
							</Button>
						</HStack>
						<HStack w="full" spacing="1rem" justifyContent="center">
							<Button
								onClick={() => {
									navigate(handlers.theme)
								}}>
								View themes
							</Button>
						</HStack>
					</ModalFooter>
				</ModalContent>
			) : isFirstPreview ? (
				<ModalContent>
					<ModalCloseButton />
					<ModalBody>
						<VStack textAlign="center" spacing="1rem">
							<Image src={'/assets/images/graphics/notification-bell.svg'} alt="Preview Started" />
							<Heading size="lg" variant="hero">
								Preview Started!
							</Heading>
							<Text>
								You&apos;ll receive an email when your preview is ready. Or, check in about {renderTime}
								.
							</Text>
						</VStack>
					</ModalBody>
					<ModalFooter>
						<HStack w="full" spacing="1rem" justifyContent="center">
							<Button onClick={onClose}>Okay, thanks!</Button>
						</HStack>
					</ModalFooter>
				</ModalContent>
			) : (
				<ModalContent>
					<ModalCloseButton />
					<ModalBody>
						<VStack textAlign="center" spacing="1rem">
							<Image
								src={'/assets/images/graphics/modal-clapper.png'}
								srcSet="/assets/images/graphics/modal-clapper@2x.png 2x, /assets/images/graphics/modal-clapper.png 1x"
								alt="Preview Started"
							/>
							<Heading size="lg" variant="hero">
								Preview #{previewJobsCount + 1}
							</Heading>
							<Text>
								The new preview will replace the current preview. You&apos;ll receive an email when your
								preview is ready. Or, check in about {renderTime}.
							</Text>
						</VStack>
					</ModalBody>
					<ModalFooter>
						<HStack w="full" spacing="1rem" justifyContent="center">
							<Button variant="ghost" onClick={onClose}>
								Cancel
							</Button>
							<Button
								onClick={() => {
									dispatchPreview()
									onClose()
								}}>
								Continue
							</Button>
						</HStack>
					</ModalFooter>
				</ModalContent>
			)}
		</Modal>
	)
}

export default ModalPreview
